import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import "./assets/Mont/Mont-Regular.ttf";
import SendBird from 'sendbird';
import Spinner from './components/common/Spinner';
import LogRocket from 'logrocket';

const App = lazy(() => import('./App'));

LogRocket.init('fmaezr/beta-prod');

new SendBird({ appId: process.env.REACT_APP_SENDBIRD_APP_ID, localCacheEnabled: true });

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback = {<Spinner/>}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
