import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import "./Spinner.scss";
import LOGO_ICON from "../../assets/images/logo_icon.png";

const override = css`
  display: block;
  margin: auto;
`;

const Spinner = () => {
  return(
    <div className="spinner_wrapper">
      <img src={LOGO_ICON} className="logo" alt="logo" />
      <PulseLoader color="#8bc2de" loading={true} css={override} size={15} />
    </div>
  )
}

export default Spinner;